<template>
    <main>
        <div class="landing-page" v-if="$store.state.storeType == 'Pure Player'">
            <section class="banner light">
                <div class="container container-large" v-if="false">
                    <div class="row">
                        <div class="col-md-12">
                            <img class="banner_product_img" src="../assets/optimum.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="container container-large" v-if="true">
                    <div class="row">
                        <div class="col-md-7 d-flex align-items-center">
                            <h2 class="banner_heading">Don’t miss these great Christmas Deals!</h2>
                        </div>
                        <div class="col-md-5 d-flex align-items-center">
                            <img class="banner_img" src="../assets/banner.png" alt="">
                        </div>
                    </div>
                    <img class="perc" src="../assets/banner_per.svg" alt="">
                </div>
            </section>
            <section class="category">
                <div class="container container-large">
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Electronics</h2>
                                <img src="../assets/catagory/catagory1.jpg" alt="">
                                <!-- <router-link to="/product-detail" >Learn more</router-link> -->
                                <a href="#">Learn more</a>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Accessories & Supplies</h2>
                                <img src="../assets/catagory/catagory2.png" alt="">
                                <a href="#">Learn more</a>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Camera & Photo</h2>
                                <img src="../assets/catagory/catagory3.png" alt="">
                                <a href="#">Learn more</a>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Computers & Accessories</h2>
                                <img src="../assets/catagory/catagory4.png" alt="">
                                <a href="#">Learn more</a>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Gaming Accessories </h2>
                                <img src="../assets/catagory/catagory5.png" alt="">
                                <a href="#">Learn more</a>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Health & Fitness</h2>
                                <img src="../assets/catagory/catagory6.png" alt="">
                                <a href="#">Learn more</a>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Beauty Products</h2>
                                <img src="../assets/catagory/catagory7.png" alt="">
                                <a href="#">Learn more</a>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="category_box">
                                <h2 class="title">Best Deals</h2>
                                <img src="../assets/catagory/catagory8.png" alt="">
                                <a href="#">Learn more</a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </section>
            <section class="gaming_section">
                <div class="container container-large">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="gaming_section_box">
                                <h2 class="title">Gaming Accessories <a href="#">Shop now</a></h2>
                                <Slick  ref="slick1" class="game_slider" :options="slickOptions">
                                <div><a href="#"><img src="../assets/slider/1.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/2.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/3.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/4.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/5.png" alt=""></a></div>
                                </Slick >
                                <button id="prev1" ref="prevArrow" class="slick-prev" @click="$refs.slick1.prev()"><img src="../assets/arrow_prev.png" alt=""></button>
                                <button id="next1" ref="nextArrow" class="slick-next" @click="$refs.slick1.next()"><img src="../assets/arrow_next.png" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="shop_banner" v-bind:style="{ backgroundImage: 'url(' + spImage + ')' }" > </section>
            <section class="gaming_section">
                <div class="container container-large">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="gaming_section_box">
                                <h2 class="title">Gaming Gifts <a href="#">Shop now</a></h2>
                                <Slick  ref="slick2" class="game_slider" :options="slickOptions2">
                                <div><a href="#"><img src="../assets/slider/slider2/1.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider2/2.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider2/3.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider2/4.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider2/5.png" alt=""></a></div>  
                                </Slick >
                                <button id="prev2" class="slick-prev" @click="$refs.slick2.prev()"><img src="../assets/arrow_prev.png" alt=""></button>
                                <button id="next2" class="slick-next" @click="$refs.slick2.next()"><img src="../assets/arrow_next.png" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="gaming_section">
                <div class="container container-large">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="gaming_section_box">
                                <h2 class="title">Top Sellers <a href="#">Shop now</a></h2>
                                <Slick  ref="slick3" class="game_slider" :options="slickOptions3">
                                <div><a href="#"><img src="../assets/slider/slider3/1.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider3/2.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider3/3.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider3/4.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider3/5.png" alt=""></a></div>
                                <div><a href="#"><img src="../assets/slider/slider3/6.png" alt=""></a></div>
                                </Slick >
                                <button id="prev3" class="slick-prev" @click="$refs.slick3.prev()"><img src="../assets/arrow_prev.png" alt=""></button>
                                <button id="next3" class="slick-next" @click="$refs.slick3.next()"><img src="../assets/arrow_next.png" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="landing-page-brick" v-if="$store.state.storeType !== 'Pure Player'">
            <div class="container-fluid">
                <div class="row main_head">
                    <div class="col-md-12">
                        <div class="box">
                            <h2 class="title">Welcome to B&C</h2>
                            <button class="brc_btn">Log in/ Register</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="banner" v-bind:style="{ backgroundImage: 'url(' + require('../assets/banner.jpg') + ')' }">
                            <img class="mobile_img" src="../assets/banner_mob_brick.png" alt="">
                            <div class="row">
                                <div class="col-md-6 offset-md-6">
                                    <div class="banner_box">
                                        <h2 class="title">Super-size for Spring!</h2>
                                        <p>Check out these offers!</p>
                                        <button class="brc_btn">Shop now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="category" v-if="false">
                            <h2 class="head">Shop by category</h2>
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <div class="category_box">
                                        <h2 class="title">Food Products</h2>
                                        <div class="category_box_img">
                                            <img src="../assets/cate1.png" alt="">
                                        </div>
                                    </div>    
                                </div>
                                <div class="col-md-2">
                                    <div class="category_box">
                                        <h2 class="title">Electronics</h2>
                                        <div class="category_box_img" style="background: #0086C3;">
                                            <img src="../assets/cate2.png" alt="">
                                        </div>
                                    </div>    
                                </div>
                                <div class="col-md-2">
                                    <div class="category_box">
                                        <h2 class="title">Toys</h2>
                                        <div class="category_box_img">
                                            <img src="../assets/cate3.png" alt="">
                                        </div>
                                    </div>    
                                </div>
                                <div class="col-md-2">
                                    <div class="category_box">
                                        <h2 class="title">Furniture</h2>
                                        <div class="category_box_img">
                                            <img src="../assets/cate4.png" alt="">
                                        </div>
                                    </div>    
                                </div>
                                <div class="col-md-2">
                                    <div class="category_box">
                                        <h2 class="title">Home & Garden</h2>
                                        <div class="category_box_img">
                                            <img src="../assets/cate5.png" alt="">
                                        </div>
                                    </div>    
                                </div>
                                <div class="col-md-2">
                                    <div class="category_box">
                                        <h2 class="title">Video Games</h2>
                                        <div class="category_box_img">
                                            <img src="../assets/cate6.png" alt="">
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                        <div class="top">
                            <h2 class="head">Top picks</h2>
                            <div class="top_picks pad-122">
                                <div class="row no-gutters">
                                    <div class="col-md-4">
                                        <div class="top_picks_box">
                                            <p class="spons">Sponsored</p>
                                            <p class="save">Save 5.00€</p>
                                            <div class="content_box">
                                                <div class="img"><img src="../assets/top/top1.png" alt=""></div>
                                                <div class="content">
                                                    <p class="desc">Sainsbury’s Crispy Triple Cooked Chips, Taste the Difference 400g (Serves 2)</p>
                                                    <p class="price">24.99€</p>
                                                    <button class="brc_btn">Add</button>
                                                    <p class="delivery">Delivery by 31/10/2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="top_picks_box">
                                            <p class="spons">Sponsored</p>
                                            <p class="save">Save 5.00€</p>
                                            <div class="content_box">
                                                <div class="img"><img src="../assets/top/top2.png" alt=""></div>
                                                <div class="content">
                                                    <p class="desc">Jus-Rol Puff Pastry Ready Rolled Sheet 320g</p>
                                                    <p class="price">24.99€</p>
                                                    <button class="brc_btn">Add</button>
                                                    <p class="delivery">Delivery by 31/10/2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="top_picks_box">
                                            <p class="spons">Sponsored</p>
                                            <p class="save">Save 5.00€</p>
                                            <div class="content_box">
                                                <div class="img"><img src="../assets/top/top3.png" alt=""></div>
                                                <div class="content">
                                                    <p class="desc">Corona 24x330ml</p>
                                                    <p class="price">24.99€</p>
                                                    <button class="brc_btn">Add</button>
                                                    <p class="delivery">Delivery by 31/10/2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="offer">
                            <div class="row no-gutters">
                                <div class="col-md-6">
                                    <div class="offer_box">
                                        <div class="offer_box_img">
                                            <img src="../assets/offer1.png" alt="">
                                        </div>
                                        <div class="offer_box_content">
                                            <h2 class="title">Sharing crisps</h2>
                                            <a href="#">Shop now</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="offer_box">
                                        <div class="offer_box_img">
                                            <img src="../assets/offer2.png" alt="">
                                        </div>
                                        <div class="offer_box_content">
                                            <h2 class="title">1L bottles from 17€</h2>
                                            <a href="#">Shop now</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="offer_box">
                                        <div class="offer_box_img">
                                            <img src="../assets/offer3.png" alt="">
                                        </div>
                                        <div class="offer_box_content">
                                            <h2 class="title">Antitabac cleaning</h2>
                                            <a href="#">Shop now</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="offer_box">
                                        <div class="offer_box_img">
                                            <img src="../assets/offer4.png" alt="">
                                        </div>
                                        <div class="offer_box_content">
                                            <h2 class="title">Beauty offers</h2>
                                            <a href="#">Shop now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="top">
                            <h2 class="head">Holiday deals on Beauty</h2>
                            <div class="top_picks">
                                <div class="row no-gutters">
                                    <div class="col-md-3">
                                        <div class="top_picks_box">
                                            <p class="save">Save 5.00€</p>
                                            <div class="content_box">
                                                <div class="img"><img src="../assets/fav-beauty/1.png" alt=""></div>
                                                <div class="content">
                                                    <p class="desc">Rimmel Lasting Finish Foundation 200 Soft Beige 30ml</p>
                                                    <p class="price">10.00€</p>
                                                    <button class="brc_btn">Add</button>
                                                    <p class="delivery">Delivery by 31/10/2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="top_picks_box">
                                            <p class="save">Save 5.00€</p>
                                            <div class="content_box">
                                                <div class="img"><img src="../assets/fav-beauty/2.png" alt=""></div>
                                                <div class="content">
                                                    <p class="desc">Rimmel London Match Silky Loose Face Powder 001 Transparent 10g</p>
                                                    <p class="price">8.50€</p>
                                                    <button class="brc_btn">Add</button>
                                                    <p class="delivery">Delivery by 31/10/2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="top_picks_box">
                                            <p class="save">Save 5.00€</p>
                                            <div class="content_box">
                                                <div class="img"><img src="../assets/fav-beauty/3.png" alt=""></div>
                                                <div class="content">
                                                    <p class="desc">Boutique Smoothing Primer 30ml</p>
                                                    <p class="price">4.00€</p>
                                                    <button class="brc_btn">Add</button>
                                                    <p class="delivery">Delivery by 31/10/2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="top_picks_box">
                                            <p class="save">Save 5.00€</p>
                                            <div class="content_box">
                                                <div class="img"><img src="../assets/fav-beauty/4.png" alt=""></div>
                                                <div class="content">
                                                    <p class="desc">L’Oreal Paris Color Riche Lipstick 303 Rose Tendre</p>
                                                    <p class="price">1.99€</p>
                                                    <button class="brc_btn">Add</button>
                                                    <p class="delivery">Delivery by 31/10/2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="offer">
                            <div class="row no-gutters">
                                <div class="col-md-6">
                                    <div class="offer_box">
                                        <div class="offer_box_img">
                                            <img src="../assets/fav/fav-prod-1.png" alt="">
                                        </div>
                                        <div class="offer_box_content">
                                            <h2 class="title">Food cupboard</h2>
                                            <a href="#">Shop now</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="offer_box">
                                        <div class="offer_box_img">
                                            <img src="../assets/fav/fav-prod-2.png" alt="">
                                        </div>
                                        <div class="offer_box_content">
                                            <h2 class="title">Fruits and vegetables</h2>
                                            <a href="#">Shop now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 pr-15">
                        <div class="box">
                            <h2 class="title">Already a customer?</h2>
                            <button class="brc_btn mb-40">Log in</button>
                            <p>Not yet registered? <a href="#">Register Now</a></p>
                        </div>
                        <div class="box">
                            <h2 class="title">New customer?</h2>
                            <p class="mb-56">Enter your postcode to check we deliver in your area.</p>
                            <button class="brc_btn">Check postcode</button>
                        </div>
                        <div class="box">
                            <h2 class="title">My profile</h2>
                            <p>Create a Custom profile for you or anyone you’re shopping for and we’ll flag any products we don’t think are suitable for you as you shop.</p>
                            <button class="brc_btn">Create</button>
                        </div>
                        <div class="box">
                            <h2 class="title"><span><img src="../assets/info.svg" alt=""></span>  Important information</h2>
                            <p>Alcohol pricing & promotions for customers served from our Scottish & Welsh stores may differ from those shown when browsing our site. Please log in to see the full range of alcohol prices & promotions available to you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    
</template>

<script>
import Slick from "vue-slick";
export default {
  name: 'Landing',
  components: { Slick },
  computed: {},
  data() {
    return { 
      name: '',
      spImage: require('../assets/shop_banner.png'),
      slickOptions: {
        slidesToShow: 1,
        infinite: true,
        variableWidth: true,
        arrows: true,
        dots: false,
        draggable: true,
        edgeFriction: 0.30,
        swipe: true,
        prevArrow: '#prev1',
        nextArrow: '#next1'
      },
      slickOptions2: {
        slidesToShow: 1,
        infinite: true,
        variableWidth: true,
        arrows: true,
        dots: false,
        draggable: true,
        edgeFriction: 0.30,
        swipe: true,
        prevArrow: '#prev2',
        nextArrow: '#next2'
      },
      slickOptions3: {
        slidesToShow: 1,
        infinite: true,
        variableWidth: true,
        arrows: true,
        dots: false,
        draggable: true,
        edgeFriction: 0.30,
        swipe: true,
        prevArrow: '#prev3',
        nextArrow: '#next3'
      },
    }
  },
  mounted() {
  },
  watch: {
    name() {}
  },
  methods: {}
}
</script>
